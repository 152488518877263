import { useTranslation } from "gatsby-plugin-react-i18next";
import * as React from "react";
import { useRef } from "react";
import SwiperCore, { Autoplay, EffectCoverflow } from "swiper";
import "swiper/css";
import "swiper/css/effect-coverflow";
import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperClass } from "swiper/types";
import { LinkButton } from "../../components/Button";
import { MainContainer, SkewContainer } from "../../components/Container";
import { SectionTitle } from "../../components/Title";
import {
  datasample_AutonomousVehicles,
  datasample_Body,
  datasample_ChatbotAnnotation,
  datasample_FacialRecognition,
  datasample_NER,
  datasample_ObjectDetection,
  datasample_OCR,
} from "../../images";

SwiperCore.use([EffectCoverflow, Autoplay]);

const DataSampleView = () => {
  const { t } = useTranslation();
  let cardRef = useRef<SwiperClass>(null);
  const hoverProps = {
    onMouseLeave: () => {
      cardRef.current?.autoplay.start();
    },
    onMouseEnter: () => {
      cardRef.current?.autoplay.stop();
    },
  };
  const items = [
    {
      title: t("自动驾驶"),
      img: datasample_AutonomousVehicles,
    },
    {
      title: t("人体部位"),
      img: datasample_Body,
    },
    {
      title: t("人脸识别"),
      img: datasample_FacialRecognition,
    },
    {
      title: t("OCR"),
      img: datasample_OCR,
    },
    {
      title: t("实体识别"),
      img: datasample_NER,
    },
    {
      title: t("多轮对话"),
      img: datasample_ChatbotAnnotation,
    },
    {
      title: t("物体识别"),
      img: datasample_ObjectDetection,
    },
  ];

  return (
    <SkewContainer fillClassName="dark-blue" className="z-20">
      <MainContainer>
        <SectionTitle id="dataSample" className="mb-8 text-white">
          {t("数据样例")}
        </SectionTitle>
      </MainContainer>
      <div className="max-w-[1520px] mx-auto mt-16 data-sample-wrap">
        <Swiper
          effect="coverflow"
          autoplay={{ delay: 2000 }}
          slidesPerView="auto"
          coverflowEffect={{
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
          }}
          centeredSlides
          loop
          onSwiper={(swiper) => {
            // @ts-ignore
            cardRef.current = swiper;
          }}
        >
          {items.map((item) => (
            <SwiperSlide key={item.title}>
              <div
                className="relative sample-item cursor-pointer"
                {...hoverProps}
              >
                <img className="rounded-md" src={item.img} alt="" />
                <p className="text-center mt-2 text-white">{item.title}</p>
                <div className="sample-item-cover absolute inset-0 bg-black bg-opacity-30 hidden flex justify-center items-center">
                  <LinkButton linkUrl="/product">{t("了解更多")}</LinkButton>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </SkewContainer>
  );
};

export default DataSampleView;
