import { graphql, navigate } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import * as React from "react";
import Tilt from "react-tilt";
import Button from "../components/Button";
import { MainContainer, PageContainer } from "../components/Container";
import Footer from "../components/Footer";
import Header, { ThemeEnum } from "../components/Header";
import Metadata from "../components/Metadata";
import SearchInput from "../components/SearchInput";
import SkewGradientBg from "../components/SkewGradientBg";
import { PageDescribe, PageTitle } from "../components/Title";
import { dataCover, DataCoverBack, DataCoverFront, DataIcon } from "../images";
import { usePageMetadata } from "../metadata/page";
import DataCustomizationView from "../Views/DataViews/DataCustomization";
import DataSampleView from "../Views/DataViews/DataSampleView";
import DataTradingPlatformView from "../Views/DataViews/DataTradingPlatformView";
import LearnMoreView from "../Views/LearnMoreView";
import { PageTitleWithIcon } from "./price";

const DataPage = () => {
  const { t } = useTranslation();
  const { data } = usePageMetadata();
  return (
    <PageContainer>
      <Header defaultTheme={ThemeEnum.dark} />
      <Metadata {...data} />
      <SkewGradientBg
        id="gradient-data"
        gradientHeight={1048}
        className="sm:h-[1048px]"
      />
      <MainContainer className="mb-20">
        <PageTitleWithIcon title={t("数据")} icon={<DataIcon />} />
        <div className="flex flex-wrap relative">
          <div className="w-full sm:w-[460px]">
            <PageTitle>{t("数据是AI时代的石油")}</PageTitle>
            <PageDescribe className="mt-6">
              {t(
                "星尘数据拥有大量高质量训练数据集，旨在打造一个AI行业的数据交易平台，不论是个人、企业，还是机构，都可在此平台进行咨询、导览、搜索和下载。"
              )}
            </PageDescribe>
          </div>
          <div className="relative sm:ml-16 mt-8 sm:mt-0">
            <img className="sm:hidden" src={dataCover} alt="" />
            <div className="sm:absolute z-30 sm:-mt-20 rounded-md hidden sm:block">
              <Tilt
                className="Tilt max-w-none w-full sm:w-[602px] sm:h-[495px]"
                options={{
                  max: 15,
                  reverse: true,
                  speed: 1500,
                  perspective: 2000,
                }}
              >
                <DataCoverBack
                  className="absolute"
                  style={{ left: "0px", top: "96px", width: "600px" }}
                />
                <div className="Tilt-inner">
                  <DataCoverFront
                    className="absolute"
                    style={{ left: "25px", top: "0px", width: "530px" }}
                  />
                </div>
              </Tilt>
            </div>
          </div>
        </div>
        <div className="w-full sm:w-[378px] mt-12">
          <SearchInput />
          <div className="flex justify-between mt-7">
            <Button onClick={() => navigate("/contact")}>
              {t("联系销售")}
            </Button>
          </div>
        </div>
      </MainContainer>
      <DataSampleView />
      <DataCustomizationView />
      <DataTradingPlatformView />
      <LearnMoreView
        leftUrl={"/contact"}
        leftText={t("填写需求表格")}
        rightText={t("了解价格")}
        rightUrl={"/price"}
      />
      <Footer />
    </PageContainer>
  );
};
export default DataPage;
export const query = graphql`
  query DataPage($language: String!) {
    ...AllLangauages
  }
`;
