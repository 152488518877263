import { useTranslation } from "gatsby-plugin-react-i18next";
import * as React from "react";
import Button from "../../components/Button";
import { SkewMainContainer } from "../../components/Container";
import { SectionTitle } from "../../components/Title";
import { DataChrome } from "../../images";

const DataTradingPlatformView = () => {
  const { t } = useTranslation();
  return (
    <SkewMainContainer fillClassName="custom-gray-bg">
      <SectionTitle className="mb-8">{t("数据交易平台")}</SectionTitle>
      <p className="disclaimer-color text-sm">
        {t(
          "星尘数据拥有大量高质量训练数据集，旨在打造一个AI行业的数据交易平台，不论是个人、企业，还是机构，都可在此平台进行咨询、导览、搜索和下载。"
        )}
      </p>
      <Button
        className="mt-8"
        onClick={() => window.open("https://store.stardust-ai.com/", "_blank")}
      >
        {t("了解更多")}
      </Button>
      <img className="mt-20" src={DataChrome} alt="" />
    </SkewMainContainer>
  );
};

export default DataTradingPlatformView;
