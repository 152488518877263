import { navigate } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import hljs from "highlight.js/lib/core";
import json from "highlight.js/lib/languages/json";
import "highlight.js/styles/base16/dracula.css";
import * as React from "react";
import { memo, useEffect, useMemo } from "react";
import Tilt from "react-tilt";
import Button from "../../components/Button";
import { SkewMainContainer } from "../../components/Container";
import { SectionTitle } from "../../components/Title";
import { DataCheck, DataDemoImage } from "../../images";

hljs.registerLanguage("json", json);

const DataCustomizationView = memo(() => {
  const { t } = useTranslation();
  const lists = useMemo(
    () => [
      t(
        "拥有全球50多个国家和地区的采集资源，支持多种场景多种类型的定制化数据采集及标注需求，服务内容涵盖图像、文本、语音、视频等全维度数据;"
      ),
      t("拥有专业的数据采集设备、丰富的数据采集项目经验及业内领先的标注平台;"),
      t(
        "在数据采集及标注过程中，严格遵守GDPR及CCPA个人隐私数据保护相关条例，并已通过ISO27701隐私信息安全管理体系认证，全面保障您的数据安全;"
      ),
      t("星尘还拥有自有的成品数据集，可以满足您算法的数据需求;"),
    ],
    []
  );
  return (
    <SkewMainContainer fillClassName="bg-white">
      <div className="">
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
          <div>
            <SectionTitle id="customData" className="mb-8">
              {t("数据定制")}
            </SectionTitle>
            <p className="disclaimer-color text-sm">
              {t(
                "为客户提供专属的数据定制服务，从数据采集，到数据集采购，一站式解决。"
              )}
            </p>
            <div>
              {lists.map((list, index) => (
                <div key={index} className="flex mt-8 items start">
                  <DataCheck className="flex-none" />
                  <p className="ml-2 -mt-0.5 disclaimer-color">{list}</p>
                </div>
              ))}
            </div>
          </div>
          <CodeHighlighted />
        </div>
        <Button className="mt-12 sm:mt-6" onClick={() => navigate("/contact")}>
          {t("了解更多")}
        </Button>
      </div>
    </SkewMainContainer>
  );
});

const CodeHighlighted = memo(() => {
  useEffect(() => {
    const code = document.querySelector("code");
    if (code == null) {
      return;
    }
    code.innerHTML = JSON.stringify(
      {
        version: "1.0.0",
        operators: [
          {
            label: "车辆",
            metadata: {},
            instanceOption: {},
            slotSpecification: {
              type: "cuboid",
            },
            key: "cuboid-[8e998]",
            type: "slotChildren",
            children: [
              {
                label: "类型",
                metadata: {},
                inputSpecification: {
                  renderConfig: {},
                  items: ["汽车", "卡车", "自行车"],
                  type: "select",
                },
                inputOption: {},
                key: "select-[81201]",
                type: "input",
              },
            ],
          },
          {
            label: "车道线",
            metadata: {},
            instanceOption: {},
            slotSpecification: {
              restrictInsideCanvasBoundary: true,
              type: "splines",
            },
            key: "splines-[2bcaa]",
            type: "slot",
          },
        ],
        record: {
          attachmentType: "IMAGE_SEQUENCE",
          attachment: [
            "/assets/road_1.jpg",
            "/assets/road_2.jpg",
            "/assets/road_3.jpg",
            "/assets/road_4.jpg",
            "/assets/road_5.jpg",
            "/assets/road_6.jpg",
            "/assets/road_7.jpg",
            "/assets/road_8.jpg",
            "/assets/road_9.jpg",
            "/assets/road_10.jpg",
            "/assets/road_11.jpg",
            "/assets/road_12.jpg",
            "/assets/road_13.jpg",
            "/assets/road_14.jpg",
            "/assets/road_15.jpg",
          ],
        },
      },
      null,
      "  "
    );

    hljs.highlightBlock(code);
  }, [hljs]);
  return (
    <div>
      <Tilt
        className="Tilt relative mt-8 max-w-none  overview-y-scroll w-full sm:w-[560px]  h-[480px]"
        options={{
          max: 15,
          reverse: true,
          speed: 1500,
          perspective: 2000,
        }}
      >
        <pre>
          <code className="absolute language-json rounded-lg w-full sm:w-[480px] h-[480px] t-0 l-0" />
        </pre>
        <div
          className="Tilt-inner absolute"
          style={{ left: "300px", top: "100px", width: "320px" }}
        >
          <img className="sm:block hidden" src={DataDemoImage} alt="" />
        </div>
      </Tilt>
    </div>
  );
});

export default DataCustomizationView;
